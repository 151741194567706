<template>
  <PageWithHeader>
    <template #title>
      <t path="">About WeTrade</t>
    </template>
    <template #body>
      <div class="content-main px-32 pt-16 pb-32">
        <t as="p" class="f-bold" path="aboutus_2">A Treuted Broker</t>
        <t as="p" class="c-title mt-8" path="aboutus_3">As a strong proponent of transparency, we establish the highest standatds of safety fot out client’s funds. For this reason, client funds are kept in major international banks and are fully segregated from the</t>
        <div class="df-middle mt-16">
          <Pic src="user/about/flag" width="125" height="80" />
          <t as="p" class="f-md c-title flex-1 ml-12" path="aboutus_reg" use-brand>WeTrade is authorised by the Financial Sector Conduct Authotity (‘FSCA’)</t>
        </div>
        <t as="p" class="f-bold mt-24" path="aboutus_5">Our Offices Worldwide</t>
        <div class="office-item d-f mt-12 br-lg" v-for="(item, index) in officeList" :key="index">
          <Pic :src="item.icon" width="159" height="96" />
          <div class="o-i-right ml-16 flex-1">
            <div class="df-middle mt-4">
              <Pic src="user/about/location" width="16" height="16" />
              <t as="p" class="f-bold f-md ml-4" :path="item.countryLable">{{item.country}}</t>
            </div>
            <t as="p" class="f-md c-title mt-8" :path="item.addressLable">{{ item.address }}</t>
          </div>
        </div>
        <t as="p" class="f-bold mt-24" path="aboutus_12">WeTrade in Numbers</t>
        <div class="d-f mt-8">
          <div class="numbers-item flex-1 df-col df-middle br-lg pb-8"
          v-for="(item, index) in numbersDataL1" :key="index"
          :class="{'mr-8': index<2}">
            <Pic class="mt-12" :src="item.icon" width="40" height="40" />
            <t as="p" class="f-bold mt-8" path="">{{item.number}}</t>
            <t as="p" class="f-md c-title mt-4" :path="item.typeLable" style="text-align: center;">{{item.type}}</t>
          </div>
        </div>
        <div class="d-f mt-12">
          <div class="numbers-item flex-1 df-col df-middle br-lg pb-8"
          v-for="(item, index) in numbersDataL2" :key="index"
          :class="{'mr-8': index<2}">
            <Pic class="mt-12" :src="item.icon" width="40" height="40" />
            <t as="p" class="f-bold mt-8" path="">{{item.number}}</t>
            <t as="p" class="f-md c-title mt-4" :path="item.typeLable" style="text-align: center;">{{item.type}}</t>
          </div>
        </div>
        <t as="p" class="f-bold mt-24 mb-8" path="aboutus_19">Our Vision</t>
        <t as="p" path="aboutus_20" multiline #="{td}" custom>
          <p class="c-title mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
        </t>
      </div>
    </template>
  </PageWithHeader>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'AboutUs',
  components: {
    PageWithHeader,
    Pic,
  },
  setup () {
    const officeList = [{
      icon: 'user/about/office_1',
      country: 'UK',
      countryLable: 'aboutus_6',
      address: 'Five Lamps Place Amiens Street, Dublin 1 lreland',
      addressLable: 'aboutus_7',
    }, {
      icon: 'user/about/office_2',
      country: 'South Africa',
      countryLable: 'aboutus_8',
      address: 'Five Lamps Place Amiens Street, Dublin 1 lreland',
      addressLable: 'aboutus_9',
    }, {
      icon: 'user/about/office_3',
      country: 'Australia',
      countryLable: 'aboutus_10',
      address: 'Five Lamps Place Amiens Street, Dublin 1 lreland',
      addressLable: 'aboutus_11',
    }]
    const numbersDataL1 = [{
      icon: 'user/about/numbers_1',
      number: '10000+',
      type: 'Daily new clients',
      typeLable: 'aboutus_13',
    }, {
      icon: 'user/about/numbers_2',
      number: '300000+',
      type: 'Active clients',
      typeLable: 'aboutus_14',
    }, {
      icon: 'user/about/numbers_3',
      number: '$80+million',
      type: 'Trading volume',
      typeLable: 'aboutus_15',
    }]
    const numbersDataL2 = [{
      icon: 'user/about/numbers_4',
      number: '3+',
      type: 'Years in the industry',
      typeLable: 'aboutus_16',
    }, {
      icon: 'user/about/numbers_5',
      number: '80+',
      type: 'Assets',
      typeLable: 'aboutus_17',
    }, {
      icon: 'user/about/numbers_6',
      number: '<10ms',
      type: 'Order execution',
      typeLable: 'aboutus_18',
    }]
    return {
      officeList,
      numbersDataL1,
      numbersDataL2,
    }
  },
})
</script>
<style scoped lang="scss">
.office-item{
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
}
.numbers-item{
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
}
</style>
